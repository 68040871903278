import { create } from "zustand";
import { FileRecord, ProjectRecord } from "@/types";
import {
  getAllProjectsReq,
  getProjectIdsReq,
  getProjectReq,
  getProjectsFilesReq,
} from "@/api/projectsApi";
import { DateRange } from "rsuite/DateRangePicker";

type State = {
  allProjects: {
    allProjects: ProjectRecord[];
    totalProjectsCount: number;
  };
  projectDetails: ProjectRecord;
  projectFiles: {
    projectFiles: FileRecord[];
    totalProjectFilesCount: number;
  };
  projectsIds: number[];
};

type Actions = {
  getAllProjects: (
    page: number,
    pageLimit: number,
    search: string,
    filter: string[],
    ptoLetterFilter: boolean,
  ) => void;
  getProjectFiles: (
    projectId: ProjectRecord["id"],
    page: number,
    pageLimit: number,
    search: string,
    filter: string[],
    dateRangeValue: DateRange | null,
  ) => void;
  getProjectDetails: (projectId: ProjectRecord["id"]) => Promise<void>;
  setProjectsDetails: (project: ProjectRecord) => void;
  getProjectsIds: (
    search?: string,
    filter?: string[],
    sort?: string,
    pto?: string,
  ) => void;
};

export const useProjectsStore = create<State & Actions>((set) => ({
  allProjects: {
    allProjects: [],
    totalProjectsCount: 0,
  },
  projectFiles: {
    projectFiles: [],
    totalProjectFilesCount: 0,
  },
  projectDetails: {} as ProjectRecord,
  projectsIds: [],
  getAllProjects: async (page, pageLimit, search, filter, ptoLetterFilter) => {
    try {
      const response = await getAllProjectsReq(
        `${page}`,
        pageLimit,
        search,
        filter,
        ptoLetterFilter,
      );
      set(() => ({
        allProjects: {
          allProjects: response.data?.projects,
          totalProjectsCount: response.data?.totalProjectsCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getProjectsIds: async (search, filter, sort, pto) => {
    try {
      const response = await getProjectIdsReq(search, filter, sort, pto);
      set(() => ({
        projectsIds: response.data?.projectsIds,
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getProjectFiles: async (
    projectId,
    page,
    pageLimit,
    search,
    filter,
    dateRangeValue,
  ) => {
    try {
      const response = await getProjectsFilesReq(
        projectId,
        `${page}`,
        pageLimit,
        search,
        filter,
        dateRangeValue,
      );
      set(() => ({
        projectFiles: {
          projectFiles: response.data?.projectDocumentations,
          totalProjectFilesCount: response.data?.totalProjectFilesCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getProjectDetails: async (projectId) => {
    try {
      const response = await getProjectReq(projectId);
      set(() => ({
        projectDetails: response?.data?.project,
      }));
    } catch (e) {
      console.error(e);
    }
  },
  setProjectsDetails: (project) => {
    set(() => ({
      projectDetails: project,
    }));
  },
}));
