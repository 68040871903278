import React, { useCallback, useEffect, useMemo } from "react";
import useTablePagination from "@/hooks/useTablePagination.ts";
import {
  Box,
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledRowActionsWrapperBox,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import TablePagination from "@/components/TablePagination";
import dayjs from "dayjs";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableFilter from "@/hooks/useTableFilter.ts";
import useDateRange from "@/hooks/useDateRange.ts";
import { useProjectsStore } from "@/store/projectsStore.ts";
import { FileRecord, ProjectRecord } from "@/types";
import { formatBytes } from "@/components/FormUploadInput";
import { updateProjectFileReq, uploadProjectFileReq } from "@/api/projectsApi";
import { PROJECTS_DOCUMENTATION_FILTER_ITEMS } from "@/constants";
import { useAuthStore } from "@/store/authStore.ts";
import FileTableItem from "@/components/FileTableItem";
import { formatDate } from "@/utils/formatDate.ts";
import ProjectInfoCard from "@/pages/ProjectDetailsPage/components/ProjectInfoCard.tsx";
import FileManagementRowActions from "@/pages/admin-pages/FileManagementPage/copmonents/FileManagement/FileManagementRowActions.tsx";
import ProjectsNavigation from "@/pages/ProjectDetailsPage/components/ProjectsNavigation.tsx";
import { useNavigate } from "react-router-dom";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import ProjectBadgesWrapper from "@/pages/ProjectDetailsPage/components/ProjectBadgesWrapper.tsx";
import CommonTableActions from "@/components/CommonTableActions";
import TableFilter from "@/components/TableFilter";
import UploadProjectFile from "@/components/UploadProjectFile";
import { getFileTypeLabel } from "@/utils/getFileTypeLabel.ts";
import { getMappedFileType } from "@/utils/getMappedFileType.ts";
import { allowEditProject } from "@/utils/allowEditProject.ts";
import { allowUploadProject } from "@/utils/allowUploadProject.ts";

function isValidDate(date: string) {
  return dayjs(date).isValid();
}

function formatOrEmpty(date: string) {
  return isValidDate(date) ? formatDate(date) : "-";
}

function createFileData(
  id: number,
  fileName: string,
  fileSize: string,
  createdAt: string,
  status: string,
  fileType: string,
  accountName: string,
  accountEmail: string,
  url: string,
  dateSubmitted: string,
  dateReviewed: string,
) {
  return {
    id,
    fileName,
    fileSize: formatBytes(Number(fileSize)),
    status,
    createdAt: formatOrEmpty(createdAt),
    fileType,
    accountName,
    accountEmail,
    url,
    dateSubmitted: formatOrEmpty(dateSubmitted),
    dateReviewed: formatOrEmpty(dateReviewed),
  };
}
const ProjectDocumentationTable = ({
  projectId,
}: {
  projectId: ProjectRecord["id"];
}) => {
  const fetchDataWithLoading = useFetchDataWithLoading();

  const enrolmentStatus = useProjectsStore(
    (state) => state.projectDetails?.enrolmentStatus,
  );

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const matchesDownLg = useMediaQuery(theme.breakpoints.down("xl"));
  const role = useAuthStore((state) => state.role);
  const { projectFiles, totalProjectFilesCount } = useProjectsStore(
    (state) => state.projectFiles,
  );
  const getProjectFiles = useProjectsStore((state) => state.getProjectFiles);

  const projectsIds = useProjectsStore((state) => state.projectsIds);

  const getProjectsIds = useProjectsStore((state) => state.getProjectsIds);

  const rows = useMemo(() => {
    return projectFiles?.map(
      ({
        id,
        fileName,
        size,
        createdAt,
        status,
        type,
        accountName,
        accountEmail,
        url,
        timeReviewd,
        timeSubmited,
      }) =>
        createFileData(
          id,
          fileName,
          size,
          createdAt,
          status,
          type,
          accountName ?? "",
          accountEmail,
          url,
          timeSubmited ?? "",
          timeReviewd ?? "",
        ),
    );
  }, [projectFiles]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalProjectFilesCount);
  const { dateRangeValue, handleDateRangeChange } = useDateRange();
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);

  const getFilesReq = useCallback(() => {
    fetchDataWithLoading(getProjectFiles, [
      projectId,
      page + 1,
      rowsPerPage,
      searchValue,
      filters,
      dateRangeValue,
    ]);
  }, [
    fetchDataWithLoading,
    getProjectFiles,
    projectId,
    page,
    rowsPerPage,
    searchValue,
    filters,
    dateRangeValue,
  ]);

  useEffect(() => {
    getFilesReq();
  }, [getFilesReq]);

  const uploadProjectFile = async (
    projectId: ProjectRecord["id"],
    data: FormData,
  ) => {
    try {
      await uploadProjectFileReq(projectId, data);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };
  const updateProjectFile = async (
    fileId: FileRecord["id"],
    data: Partial<FileRecord>,
  ) => {
    try {
      await updateProjectFileReq(fileId, data);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };

  const isAdmin = role === "administrator";

  return (
    <>
      <ProjectInfoCard getFilesReq={getFilesReq} />
      <Box sx={{ width: "100%", position: "relative" }}>
        <StyledTableCard sx={{ width: "100%" }}>
          <CommonTableActions
            title={"Project documentation"}
            onSearch={handleDebounceSearchValue}
            filter={
              <TableFilter
                placeholder={"Status"}
                items={PROJECTS_DOCUMENTATION_FILTER_ITEMS}
                setFilterValues={handleFilterChange}
              />
            }
            onDateRange={handleDateRangeChange}
            rightActions={
              allowUploadProject(role, enrolmentStatus) ? (
                <UploadProjectFile
                  isButton={true}
                  uploadFile={(data) => uploadProjectFile(projectId, data)}
                />
              ) : null
            }
          />
          <TableContainer>
            <StyledTable aria-label="customized table">
              <StyledTableHeader>
                <TableRow>
                  <StyledTableCell>File name</StyledTableCell>
                  <StyledTableCell>Document type</StyledTableCell>
                  <StyledTableCell>File size</StyledTableCell>
                  <StyledTableCell>Date & time Submitted</StyledTableCell>
                  <StyledTableCell>Date & time Reviewed</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </StyledTableHeader>
              <TableBody>
                {rows.map((row) =>
                  downSm ? (
                    <CollapsableRow
                      key={row.id}
                      title={row.fileName}
                      fileName={row.fileName}
                      firstSub={getFileTypeLabel(row.fileType)}
                      secondSub={
                        <ProjectBadgesWrapper
                          isDeleted={row.status === "deleted"}
                          isAdmin={isAdmin}
                          rowId={row.id}
                          rowStatus={row.status}
                          updateProjectFile={updateProjectFile}
                        />
                      }
                    >
                      <CellWithHeader header={"File size"}>
                        {row.fileSize}
                      </CellWithHeader>
                      <CellWithHeader header={"Date & time Submitted"}>
                        {row.dateSubmitted}
                      </CellWithHeader>
                      <CellWithHeader header={"Date & time Reviewed"}>
                        {row.dateReviewed}
                      </CellWithHeader>
                      <StyledRowActionsWrapperBox>
                        {role && (
                          <FileManagementRowActions
                            status={row.status}
                            rowId={row.id}
                            getFilesReq={getFilesReq}
                          />
                        )}
                      </StyledRowActionsWrapperBox>
                    </CollapsableRow>
                  ) : (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>
                        <FileTableItem
                          fileName={row.fileName}
                          url={`project/document/download/${row.id}`}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        {getFileTypeLabel(row.fileType)}
                      </StyledTableCell>
                      <StyledTableCell>{row.fileSize}</StyledTableCell>
                      <StyledTableCell>{row.dateSubmitted}</StyledTableCell>
                      <StyledTableCell>{row.dateReviewed}</StyledTableCell>
                      <StyledTableCell>
                        <ProjectBadgesWrapper
                          isDeleted={row.status === "deleted"}
                          isAdmin={isAdmin}
                          rowId={row.id}
                          rowStatus={row.status}
                          updateProjectFile={updateProjectFile}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <StyledRowActionsWrapperBox>
                          {role && (
                            <FileManagementRowActions
                              status={row.status}
                              rowId={row.id}
                              getFilesReq={getFilesReq}
                            />
                          )}
                        </StyledRowActionsWrapperBox>
                      </StyledTableCell>
                    </StyledTableRow>
                  ),
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <TablePagination
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={totalProjectFilesCount}
          />
        </StyledTableCard>
        {!matchesDownLg && (
          <ProjectsNavigation
            navigateTo={(projectId, searchParams) => {
              navigate(`/projects/${projectId}?${searchParams.toString()}`);
            }}
            ids={projectsIds}
            getIds={getProjectsIds}
          />
        )}
      </Box>
    </>
  );
};

export default ProjectDocumentationTable;
